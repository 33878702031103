const zh = {
  tips: {
    wlTips: '您尚未有白名單資格，請在24小時之後的公售階段前來 mint',
    wlMintMax: '你的铸造达到上限',
    cancelTx: '交易取消',
    unlockWallet: '請解鎖錢包',
    fundNotEnough: '錢包 ETH 不足',
    wrongNetwork: '請使用以太坊主網',
  },
  common: {
    zh: '中文版CN',
    en: 'ENGLISH',
    mint: '鑄造',
    Now: '現在',
    soldout: '售完',
    ongoing: '進行中',
    yetstart: '即將開始',
    chamber: '宇宙客廳',
    Chamber: '客廳',
    numbers: '數量',
    limitTime: '限時',
    Cosmo: '宇宙',
    ComingSoon: '即將發行',
    partner: '合作夥伴',
    wlMint: '{price} ETH 鑄造',
    whitePriceDesc: '白名單 {price} ETH',
    minted: '已鑄造',
  },
  nav: {
    home: '首頁',
    gallery: '畫廊',
    puzzle: '合成',
  },
  spirit: {
    pumpkin: '南瓜',
  },
  home: {
    activityContent1: '第四階段白名單活動正在進行，進入discord',
    activityContent2: '最新活動每天都有進行，進入Twitter',
    r1: '將有6001枚 重屏 NFTs用於曲線定價銷售',
    r2: '留給美術館收藏，策展人，文化機構',
    r3: '一系列特殊的NFT留給超级项目 所有者',
    r4: '團隊和投資人將保留 57 NFTs',
    ROADMAP: '路線圖',
    roadmapContent: `
o 近期目標 <br>
內容線性解鎖，給後續帶來持續的支持<br>
周邊衍生品設計生產，背靠成熟供應鏈<br>
启动小精灵共创<br>
<br>
o 中期目標<br>
Sandbox重屏美術館，web3創作者聯展<br>
線下藝術輕奢店計劃，重屏實體屏風拍賣<br>
小精靈獲取和合成玩法<br>
<br>
o 長期目標<br>
特殊款拍賣，主流藝術機構加持<br>
進軍國際展覽（已達成紐約展出，已洽談歐洲一線大展）<br>
启动更宏伟的时空观“千层塔“研发,持有者和访问者皆有神趣<br>
对持仓用户空投可居可游的“东方空间“精灵解密玩法穿插其间<br>
    `,
    p1: '階段1',
    p1Content: '從空白而生',
    p2: '階段2',
    p2Content: '屏風隔開的時間',
    p3: '階段3',
    p3Content: '沈浸於當下的享樂主義',
    p4: '階段4',
    p4Content: '裂開縫隙的元宇宙',
    Investor: '團隊',
    Grant: '領取',
    Culture: '文化回馈',
    Sales: '銷售數量',
    EmptyRoom: '房間',
    Puzzle: '解謎',
    About: '關於項目',
    spiritsForPuzzle: '合成',
    Gallery: '畫廊',
    phase: '階段三 <br/> 沈浸於當下的享樂主義',
    mintDesc: '鑄造一個宇宙客廳',
    mintSpirit: '鑄造宇宙精靈',
    cosmoDesc: 'Connecting the two sides of a screen, a new portal opens',
    mintNow: '立即鑄造',
    Combine: '合成',
    coming: '即將上線',
    banner: {
      title: '一個重疊空間里通向不同宇宙',
      content: '多層嵌套組合式NFT藝術品',
    },
    ifYouHave: '如果你有',
    aboutPart1: `<br>
6001枚不重復的NFT構建一個嵌套的宇宙。<br>
這是一個頗具野心的項目。首先被吸引的是屏風，月門，迷霧，無限循環的窗外，空氣中飛行的「財神」，好看的很玄幻。
<br><br>
它是一個連接器，去到各個藝術家內心宇宙的中央大廳
<br><br>
本次有4位藝術家和一個紐約藝術KOL參與了項目：<br>
四位藝術家都是在各自領域里非常知名的。<br>
* 把繪畫性和文學性結合完美的 GA<br>
* 融合東方神秘主義，佛教，刺青文化的ARMOTOR<br>
* 沈醉和浸淫在東方工筆畫的TONG<br>
* 喜歡結合3D的地形和古代山水畫的 ZOOJOO<br>
<br><br>
項目分4個階段的發行，中間不斷有小精靈道具和房間做合成互動。<br>
比如一隻貓被一隻南瓜吞噬後變成南瓜貓。<br>
房間NFT和精靈NFT會有各種有趣的互動。`
  },
  gallery: {
    lotusRole: '荷趣',
    lotusRoleContent: '以太坊涨价时候我才感觉荷叶颇有异趣。',
    sageRole: '只读圣贤书',
    sageRoleContent: '读书可以，但需有手机相伴。',
    elderScreen: '老章鱼和骨船',
    elderScreenContent: '五千岁的章鱼已可化作人形，在没有月光的夜里，搭建只有他能驾驶的没有帆板的骨船。',
    blankScreen: '空白屏風',
    blankScreenContent: '隱藏款。',
    spiritItem: '精靈道具',
    title: '擁有它，打開每一道宇宙之門',
    mirrorcat: '照镜子猫。那不是镜子，那是去另一个宇宙的入口。',
    pumpkin: '南瓜。 这是一个晃动的南瓜，时不时还会发出猫的叫声。',
    floralspirit: '花精。拟态花朵，实际是高维度临凡的间谍，不信你仔细看。',
    vase: '花瓶。平淡的花瓶里插着的其实是一种动物。',
    screen: '屏风',
    screenTitle: '进入它，感受它',
  }
}

const en = {
  tips: {
    wlTips: 'Presale is limited to whitelisted users only. \n' + 'Public sale will begin 24 hours after presale.',
    wlMintMax: 'You have reached maximum mint qty',
    cancelTx: 'Transaction denied',
    unlockWallet: 'Please unlock wallet first',
    fundNotEnough: 'Insufficient fund',
    wrongNetwork: 'Please switch network to ETH mainnet'
  },
  gallery: {
    lotusRole: 'Lotus Allure',
    lotusRoleContent: 'I find the lotus leaves quite interesting just when Ether price rises.',
    sageRole: 'Sage Book Only',
    sageRoleContent: 'Reading is fine, but got to have my phone with me.',
    elderScreen: 'Elder Octopus and Osseous Boat',
    elderScreenContent: ' A 5000-year-old octopus transforms itself into human, who, on a moonless night, assembles a boat with just bones and no sail, that only he can pilot.',
    screen: 'Screen',
    screenTitle: 'Enter it，feel it',
    spiritItem: 'Spirit items',
    title: 'Own it, open the gateway of universe',
    mirrorcat: 'Mirror Cat. That is not a mirror, but a portal to another cosmos.',
    pumpkin: 'Pumpkin. This is a shaking pumpkin and occasionally makes a cat cry.',
    floralspirit: 'Floral Spirit. A spy from a higher dimension that disguises as a flower. Look closer and you will see.',
    vase: 'Vase. Ordinary vase used for displaying certain kind of animal instead of flowers.',
    blankScreen: 'Blank Screen',
    blankScreenContent: 'Blank Screen. Hidden Edition.',
  },
  common: {
    zh: '中文版CN',
    en: 'ENGLISH',
    mint: 'Mint',
    limitTime: 'Limited time',
    ComingSoon: 'Coming soon',
    soldout: 'sold out',
    chamber: 'Cosmo Chamber',
    ongoing: 'ongoing',
    numbers: 'Numbers',
    yetstart: 'yet to start',
    partner: 'Partner',
    minted: 'Minted',
    whitePriceDesc: 'WL {price} ETH',
    wlMint: '{price} ETH MINT'
  },
  nav: {
    home: 'MAIN',
    gallery: 'GALLERY',
    puzzle: 'PUZZLE',
  },
  home: {
    Combine: 'Combine',
    ROADMAP: 'Roadmap',
    Sales: 'Sales',
    Culture: 'Culture',
    Grant: 'Grant',
    banner: {
      title: 'An Overlapping Space that Leads to Multiple Cosmos',
      content: 'It is an entrance, a connector, to the chamber of each artist\'s inner universe'
    },
    roadmapContent: `
Short-term objective: <br> <br>
1.Public sale will end once we reach 2,000 items sold in Phase 4. The rest will be given to institutions for distribution.<br> <br>
2.The design and production of physical merchandises. <br> <br>
3.We will invite members of the community to design and create characters and items for the “Spirit” category.<br> 
<br>
Midterm objective: <br> <br>
1.We will build a museum for the project in Sandbox and curate a group show for web 3 content creators.<br> <br>
2.The creation of physical screens based on the screens in CosmoChamber. <br> <br>
3.The development of more advanced play/puzzle mechanism involving the combination of Spirits and Chambers.<br> <br>
<br>
Long-term objective: <br> <br>
1.Public sale of special editions in traditional auction houses. <br> <br>
2.Participation in international exhibitions. <br> <br>
3.The development of CosmoTower, a more complex version of the current project, which will allow both the NFT owners and visitors to view and play. <br> <br> 
4.The design and development of “Eastern Spaces” which will be airdropped to existing chamber owners. <br>
    `,
    r1: '6,001 Cosmo Chamber NFTs will be used for curve pricing sales',
    r2: '500 NFTs are reserved for art galleries, curators and cultural institutions',
    r3: 'A unique NFT reserved for cryptopunk owners.',
    r4: 'Investors will receive a total of 57 NFTs.',
    p1: 'Phase 1',
    About: 'About',
    p1Content: 'An Eastern aesthetics originated from the void',
    p2: 'Phase 2',
    p2Content: 'The birth of the Screen',
    p3: 'Phase 3',
    p3Content: 'The hedonism of living in the moment',
    p4: 'Phase 4',
    p4Content: 'A fissure in the Metaverse',
    activityContent1: 'Join our Discord to get whitelisted for Phase 4.',
    activityContent2: 'Follow our Twitter to learn more about daily activities and updates. ',
    Investor: 'Investors',
    EmptyRoom: 'Empty room',
    phase: 'Phase THREE <br/> The hedonism of living in the moment',
    mintDesc: 'to mint one Cosmo Chamber',
    mintSpirit: 'to mint one Cosmo Spirit',
    cosmoDesc: 'Connecting the two sides of a screen, a new portal opens',
    mintNow: 'MINT NOW',
    coming: 'WHAT\'S COMING',
    spiritsForPuzzle: 'spirits for puzzle',
    ifYouHave: 'If you have it',
    aboutPart1: `<br>
        The project takes its inspiration from The Double Screen, a book on the role of the traditional Eastern screen seen in Classical Chinese paintings by the acclaimed art historian Wu Hung of the University of Chicago, and explores an alternative understanding of spacetime. It is a portal, a node, through which the creative minds of artists can connect.
        <br><br>
        Each artist brings their unique visual style into the project while sharing a collective interest in the sensibility of the Far East.
        <br><br>
        They are:
        <br><br>
        GA, who fuses the painterly and the literary in her work. <br>
        ARMOTOR, who traverses fluently between Eastern Mysticism, Buddhism, and Tattoo Culture. <br>
        TONG, whose practice is immersed in the tradition of Ming-Dynasty gongbi painting style. <br>
        Z, who combines classical landscape painting with computer-generated 3D geography. <br>
        <br>
        What you collect is not the work of a single artist but a connector to the NFT art in the Metaverse.`
  }
}

module.exports = {
  zh, en
}
