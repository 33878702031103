<template>
  <div class="grantBox">
    <div class="numberBox">
      <p>0.03 ETH to mint for whitelisted collectors only</p>
      <div class="number">
        <strong>Numbers</strong>
        <input type="hidden" disabled v-model="mintNumber" />
        <span class="box">
          <div class="sub" @click="subMint">-</div>
          <span>{{mintNumber}}</span>
          <div class="add" @click="addMint">+</div>
        </span>
      </div>
    </div>
    <div class="infoBox">
      <p>Collectors that are on the whitelist can mint up to 2 NFTs for a cost that is significantly lower than the regular price. There are 200 works to begin with which are to be claimed on a first-come, first-serve basis. </p>
      <p>A new whitelist will be generated every week and the people on the list will have an one-week window to mint. Follow our Twitter page and Discord channel for the latest rules on how you can be added to list.</p>
    </div>
    <div class="btnBox">
      <button type="button" class="getMint" v-if="isGetMint" @click="mint">MINT Now</button>
      <button type="button" disabled v-else>Not in list</button>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js'
import {mapState} from "vuex";

export default {
  name: "grantMobile",
  computed: {
    ...mapState(['account', 'web3', 'networkid', 'nav', 'nftContract', 'whitelistContract']),
  },
  data() {
    return {
      mintNumber: 2,
      isGetMint: true
    }
  },
  methods: {
    subMint() {
      if (this.mintNumber <= 0) {
        return;
      }
      this.mintNumber--;
    },
    addMint() {
      if (this.mintNumber >= 2) {
        return;
      }
      this.mintNumber++;
    },

    async mint() {
      if (this.whitelistContract) {
        const ethValue = 0.03 * this.mintNumber * 10**18
        const gasPrice = await this.web3.eth.getGasPrice()
        const gasLimit = await this.whitelistContract.methods.mint(this.mintNumber).estimateGas({
          from: this.account,
          gas: gasPrice,
          value: ethValue,
        })

        this.whitelistContract.methods.mint(this.mintNumber).send({
          from: this.account,
          gasPrice,
          gas: gasLimit,
          value: ethValue,
        }, function(err, hash) {
          console.log(err, hash)
        })
      }
    }
  },

  mounted() {
    this.$store.commit('switchNavi', 2)
    const _this = this
    setInterval(async function() {
      if (_this.whitelistContract) {
        const isWhitelist = await _this.whitelistContract.methods.IfWhiteList(_this.account).call()
        _this.isGetMint = isWhitelist
      }
    }, 2000)
  }
}
</script>

<style lang="scss" scoped>
  .grantBox {
    padding-top: 50px;
    background: url("../assets/grant/hand.png") no-repeat center top;
    background-size: contain;

    .numberBox {
      box-sizing: border-box;
      width: 700px;
      margin: 0 auto 0;
      padding: 0 24px;
      border: 1px solid #979797;
      border-radius: 16px;
      background-color: #fff;

      p {
        font-size: 20px;
        color: #131415;
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid #DEDEDE;
      }

      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0 10px;
        strong {
          margin-right: 20px;
          font-size: 20px;
          color: #131415;
        }
        input {
          box-sizing: border-box;
          width: 60px;
          height: 24px;
          margin-right: 20px;
          padding-left: 20px;
          font-size: 20px;
          color: #979797;
          border: 1px solid #979797;
        }
        .box {
          display: flex;
          .sub,.add {
            width: 48px;
            height: 48px;
            text-align: center;
            border: 1px solid #99A5BF;
            font-size: 28px;
            cursor: pointer;
            color: #99A5BF;
          }
          span {
            margin: 0 20px;
            font-size: 34px;
            color: #474758;
          }
        }
      }
    }
    .infoBox {
      width: 700px;
      margin: 30px auto 0;
      p {
        font-size: 18px;
        color: #131415;
        text-align: justify;
      }
    }

    .btnBox {
      margin-top: 80px;
      text-align: center;
      button {
        width: 300px;
        height: 60px;
        font-size: 20px;
        color: #111;
        border: 0;
        cursor: pointer;
        background: #E0E0E0;
        border-radius: 16px;
      }
      button.getMint {
        background-color: #FFC938;
      }
    }
  }
</style>
