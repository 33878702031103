import Vue from 'vue'
import Vuex from 'vuex'
import nftABI from './constants/nftABI.json'
import itemABI from './constants/itemABI.json'
import whitelistABI from './constants/whitelistABI.json'
import crossABI from './constants/crossABI.json'
import punkABI from './constants/punkABI.json'
import saleABI from './constants/saleABI.json'

Vue.use(Vuex)
const local = false
export default new Vuex.Store({
  state: {
    nav: 0,
    web3: null,
    account: null,
    chainid: local ? '4' : '0x1',
    networkid: local ? 4 : 1,
    ethereum: null,
    mintData: null,
    lang: 'en',
    blockNumber: '--',
    contracts: {
      NFT: local ? '0x5AfF998bA7407334a3330a8245A99BEb2cda3cf5' : '0x5ab97a44d024871ba1c0df8c9be97387bbe844c8',
      Sale: local ? '0xe2A7ECB1c7985B9528f2794d5c431b4E9c9a98CE' : '0x34bb281cfa125446310b2e4aef2b0de625b2c105',
      Item: local ? '0xc5a0012a0BD2d532f11F41aa9985D3b3C5a911f9' : '0x6079688b5b3657eb496248bf4259508904d9e0b4',
      Cross: local ? '0xE430C759D99F4382b98D7c62F4df8D96da4a70ab' : '0x9de112d3c287e1e30f6bd274d590de8fd2e535b6',
      Punk: local ? '0xE5cfa5fA694E2aDC1872349edFD806B80a1f1184': '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB',
      Whitelist: local ? '0x8A73070C2dEFa10664A9F8fad3422bf5010B548C' : '0x380d98f200c1e555603d35127aeb25709ab4544e',
      itemABI,
      nftABI,
      crossABI,
      punkABI,
      saleABI,
      whitelistABI,
    },
    nftContract: null,
    saleContract: null,
    punkContract: null,
    whitelistContract: null,
    itemContract: null,
    crossContract: null,
  },
  mutations: {
    switchNavi(state, nav) {
      state.nav = nav
    },
    setWeb3(state, web3) {
      state.web3 = web3
      state.nftContract = new web3.eth.Contract(nftABI, state.contracts.NFT)
      state.saleContract = new web3.eth.Contract(saleABI, state.contracts.Sale)
      state.punkContract = new web3.eth.Contract(punkABI, state.contracts.Punk)
      state.whitelistContract = new web3.eth.Contract(whitelistABI, state.contracts.Whitelist)
      state.itemContract = new web3.eth.Contract(itemABI, state.contracts.Item)
      state.crossContract = new web3.eth.Contract(crossABI, state.contracts.Cross)
    },
    setLang(state, lang) {
      state.lang = lang
    },
    setEthereum(state, ethereum) {
      state.ethereum = ethereum
    },
    setMintData(state, mintData) {
      state.mintData = mintData
    },
    setAccount(state, account) {
      state.account = account
    },
    setBlockNumber(state, blockNumber) {
      state.blockNumber = blockNumber
    },
    setNetworkid(state, networkid) {
      state.networkid = networkid
    },
    async checkAccount(state) {
      if (state.web3) {
        const netID = await this.web3.eth.net.getId()
        if (netID === this.networkid) {
          state.ethereum.enable()
          state.web3.eth.getAccounts((err, accounts) => {
            this.commit('setAccount', accounts[0])
          });
        }
      }
    }
  },
  actions: {},
  modules: {}
})
