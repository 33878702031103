<template>
  <div class="grantBox">
    <div class="numberBox">
      <p>0.02 eth to mint for whitelisted collectors only</p>
      <div class="number">
        <strong>Numbers</strong>
        <input type="text" disabled v-model="mintNumber" />
        <span class="box">
          <div class="sub" @click="subMint">-</div>
          <span>{{mintNumber}}</span>
          <div class="add" @click="addMint">+</div>
        </span>
      </div>
    </div>
    <div class="infoBox">
      <p>Collectors that are on the whitelist can mint up to 2 NFTs for a cost that is significantly lower than the regular price. There are 200 works to begin with which are to be claimed on a first-come, first-serve basis. </p>
      <p>A new whitelist will be generated every week and the people on the list will have an one-week window to mint. Follow our Twitter page and Discord channel for the latest rules on how you can be added to list.</p>
    </div>
    <div class="btnBox">
      <button type="button" class="getMint" v-if="isGetMint" @click="getMint">MINT Now</button>
      <button type="button" disabled v-else>coming soon</button>
    </div>


    <div class="popupBox">
      <div class="popupTitle">
        <strong>MINT</strong>
        <span class="closePopup">X</span>
      </div>
      <div class="popupMain">
        <div class="popupNumberBox">
          <p>0.05 ETH / CC nft TO ALL</p>
          <div class="number">
            <strong>Numbers</strong>
            <input type="text" disabled v-model="mintNumber" />
            <span class="box">
              <div class="sub" @click="subMint">-</div>
              <span>{{mintNumber}}</span>
              <div class="add" @click="addMint">+</div>
            </span>
          </div>
        </div>

        <div class="popupBtn canUse">
          <span>MINT Now</span>
        </div>
      </div>
    </div>

    <!-- 示例二，loading -->
    <div class="popupBox">
      <div class="popupTitle">
        <strong>LOADING</strong>
        <span class="closePopup">X</span>
      </div>
      <div class="popupMain">
        <div class="popupContent">
          <p>Please be patient. It will take time to build the cosmic brick</p>
        </div>

        <div class="popupBtn unUse">
          <span>waiting…</span>
        </div>
      </div>
    </div>

    <div class="popupBox">
      <div class="popupTitle">
        <strong>LOADING</strong>
        <span class="closePopup">X</span>
      </div>
      <div class="popupMain">
        <div class="popupTips">
          <p class="error"><span>x</span>Unable to connect wallet</p>
          <p class="success"><span>x</span> Minting transaction complete!</p>
          <p class="next">Sold out, but wait for the next cycle</p>
        </div>

        <div class="popupBtn unUse">
          <span>connect again</span>
        </div>
        <div class="popupBtn canUse">
          <span>View (OPENSEA)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "grant",
  data() {
    return {
      mintNumber: 2,
      isGetMint: false
    }
  },
  mounted() {
    this.$store.commit('switchNavi', 2)
  },
  methods: {
    subMint() {
      if (this.mintNumber <= 0) {
        return;
      }
      this.mintNumber--;
    },
    addMint() {
      if (this.mintNumber >= 2) {
        return;
      }
      this.mintNumber++;
    },
    getMint() {

    }
  }
}
</script>

<style lang="scss" scoped>
  .grantBox {
    width: 750px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 150px;
    background: url("../assets/grant/hand.png") no-repeat center top;
    background-size: contain;
    .numberBox {
      box-sizing: border-box;
      width: 700px;
      margin: 0 auto 0;
      padding: 0 48px;
      border: 1px solid #979797;
      border-radius: 16px;
      background-color: #fff;
      p {
        font-size: 28px;
        color: #131415;
        padding: 20px 0;
        text-align: center;
        border-bottom: 1px solid #DEDEDE;
      }
      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 0 20px;
        strong {
          margin-right: 40px;
          font-size: 28px;
          color: #131415;
        }
        input {
          box-sizing: border-box;
          width: 120px;
          height: 48px;
          margin-right: 20px;
          padding-left: 20px;
          font-size: 32px;
          color: #979797;
          border: 1px solid #979797;
        }
        .box {
          display: flex;
          .sub,.add {
            width: 48px;
            height: 48px;
            text-align: center;
            border: 1px solid #99A5BF;
            font-size: 28px;
            color: #99A5BF;
          }
          span {
            margin: 0 20px;
            font-size: 34px;
            color: #474758;
          }
        }
      }
    }
    .infoBox {
      width: 700px;
      margin: 50px auto 0;
      p {
        font-size: 28px;
        color: #131415;
        text-align: justify;
      }
    }
    .btnBox {
      width: 750px;
      margin-top: 200px;
      text-align: center;
      button {
        width: 600px;
        height: 80px;
        font-size: 28px;
        color: #111;
        border: 0;
        background: #E0E0E0;
        border-radius: 16px;
      }
      button.getMint {
        background-color: #FFC938;
      }
    }

    .popupBox {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 670px;
      margin: 0 auto;
      .popupTitle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 670px;
        height: 90px;
        background: #FFC938;
        border-radius: 32px 32px 0px 0px;
        strong {
          font-size: 36px;
          color: #131415;
        }
        .closePopup {
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 30px;
          font-size: 28px;
          color: #131415;
          font-weight: bold;
        }
      }
      .popupMain {
        width: 670px;
        padding: 40px 0;
        border-radius: 0 0 32px 32px;
        background-color: #E9E9E9;
        .popupNumberBox {
          box-sizing: border-box;
          width: 600px;
          margin: 0 auto 20px;
          border-radius: 16px;
          background-color: #fff;
          overflow: hidden;
          p {
            font-size: 28px;
            color: #131415;
            padding: 20px 0;
            text-align: center;
            border-bottom: 1px solid #DEDEDE;
          }
          .number {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 40px 0 20px;
            strong {
              margin-right: 40px;
              font-size: 28px;
              color: #131415;
            }
            input {
              box-sizing: border-box;
              width: 120px;
              height: 48px;
              margin-right: 20px;
              padding-left: 20px;
              font-size: 32px;
              color: #979797;
              border: 1px solid #979797;
            }
            .box {
              display: flex;
              .sub,.add {
                width: 48px;
                height: 48px;
                text-align: center;
                border: 1px solid #99A5BF;
                font-size: 28px;
                color: #99A5BF;
              }
              span {
                margin: 0 20px;
                font-size: 34px;
                color: #474758;
              }
            }
          }
        }
        .popupContent {
          width: 600px;
          margin: 0 auto 20px;
          padding: 60px 30px;
          background-color: #fff;
          border-radius: 16px;
          p {
            text-align: center;
            font-size: 28px;
            color: #131415;
          }
        }
        .popupTips {
          width: 600px;
          margin: 0 auto 100px;
          p {
            text-align: center;
          }
          .error {
            font-size: 28px;
            color: #D0021B;
            span {
              margin-right: 20px;
              background-color: #FF5B5B;
            }
          }
          .success {
            font-size: 28px;
            color: #131415;
            span {
              margin-right: 20px;
              background-color: #00B578;
            }
          }
          .next {
            font-size: 36px;
            color: #131415;
          }
        }
        .popupBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 600px;
          height: 80px;
          margin: 0 auto;
          font-size: 28px;
          color: #131415;
          border-radius: 16px;
        }
        .canUse {
          background: #FFC938;
        }
        .unUse {
          background-color: #fff;
          border: 1px solid #FFC938;
        }
      }

    }
  }
</style>
