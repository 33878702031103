<template>
  <header>
    <div class="topbar">
      <div>
        <img src="../../public/img/logo.png">
        <div class="nav-item">PROJECTS:</div>
        <div class="nav-item">COSMOCHAMBER</div>
        <div class="nav-item">|</div>
        <a href="http://www.herrgallery.com/"><div class="nav-item">NYC MURALS</div></a>
        <div class="nav-item">|</div>
<!--        <div class="nav-item">BUGHISTORY <span class="tag">free</span></div>-->
<!--        <div class="nav-item">|</div>-->
        <div class="nav-item">ABOUT</div>
        <Wallet ref="wallet"></Wallet>
      </div>
      <div>
        <div class="connect-btn" v-if="!account" @click="connect">Connect Wallet</div>
        <div class="connect-btn" v-else>{{shortAccount}}</div>
      </div>
    </div>
    <header class="header">
      <span class="logo">COSMO CHAMBER NFT</span>
      <!-- <img class="logo" src="../assets/logo.png" > -->
      <div class="nav">
        <router-link to="/" @click="switchNav(0)">
          <div class="nav-item" :class="{active: nav === 0}">{{$t('nav.home')}}</div>
        </router-link>
        <router-link to="/gallery" @click="switchNav(1)">
          <div class="nav-item" :class="{active: nav === 1}">{{$t('nav.gallery')}}</div>
        </router-link>
        <router-link to="/puzzle" @click="switchNav(3)">
          <div class="nav-item" :class="{active: nav === 3}">{{$t('nav.puzzle')}}</div>
        </router-link>
<!--        <a href="https://www.element.market/collections/cosmochamber" target="_blank">-->
<!--          <div class="nav-item">Element</div>-->
<!--        </a>-->
        <a href="https://opensea.io/collection/cosmochamber" target="_blank">
          <div class="nav-item">OpenSea</div>
        </a>
        <a href="http://www.herrgallery.com" target="_blank">
          <div class="nav-item">HERR @NY</div>
        </a>
        <div class="social-links">
          <a href="https://www.instagram.com/cosmochamber.nft/" target="_blank">
            <img src="/img/ins.png">
          </a>
          <a href="https://discord.gg/TJhzDTkNdN" target="_blank">
            <img src="/img/discord.png">
          </a>
          <a href="https://twitter.com/CosmoChamber" target="_blank">
            <img src="../assets/twitter.png">
          </a>
        </div>
        <div class="lang-options">
          <div class="lang-item" :class="{active: lang === 'zh'}" @click="switchLang('zh')">{{$t('common.zh')}}</div>
          <div class="lang-item" :class="{active: lang === 'en'}" @click="switchLang('en')">{{$t('common.en')}}</div>
        </div>
      </div>
    </header>
  </header>
</template>

<script>
import { Icon, message } from 'ant-design-vue'
import {mapMutations, mapState} from "vuex"
import Wallet from '../components/Wallet'
export default {
  name: "Header",
  watch: {
    nav: function(value) {
      this.current = value
    },
    locale (val) {
      this.$i18n.locale = val
    }
  },
  components: {
    Wallet,
  },
  computed: {
    ...mapState(['account', 'web3', 'networkid', 'nav']),
    shortAccount() {
      if (this.account) {
        return this.account.substr(0, 6) + '....' + this.account.substr(this.account.length - 6, this.account.length - 1)
      }

      return ''
    },
  },
  methods: {
    ...mapMutations(['setLang']),
    switchLang(lang) {
      this.lang = lang
      this.setLang(lang)
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
    },
    switchNav(nav) {
      this.$store.commit('switchNavi', nav)
    },
    async connect() {
      if (!this.web3) {
        message.error(this.$t('tips.unlockWallet'))
        return
      } else {
        if (this.account) {
          return
        } else {
          const networkid = await this.web3.eth.net.getId()
          if (Number(networkid) === this.networkid) {
            this.$refs.wallet.checkAccounts()
          } else {
            message.error(this.$t('tips.wrongNetwork'))
            return
          }
        }
      }
    }
  },
  data() {
    return {
      current: this.nav,
      lang: localStorage.getItem('lang') || 'en',
    }
  },
  mounted() {
    this.$store.commit('switchNavi', 1)
    document.getElementsByClassName('ant-select-selection--single').style="background: black"
    this.switchLang(this.lang)
  }
}
</script>

<style lang="scss" scoped>
  .tag {
    background: #FF8B00;
    padding: 2px 4px;
    margin-left: 4px;
    color: white;
  }
  :global{

    .ant-select-dropdown{

      border-radius: 0 0 10px 10px;   // 圆角
      overflow: hidden;
      .ant-select-dropdown-menu,.ant-select-dropdown-menu-root,.ant-select-dropdown-menu-vertical{

        li:hover{
          // 鼠标 hover 效果
          background-color: rgba(132, 63, 255,0.4);
        }
        background-color: #fff; // 背景色
      }
      .ant-select-dropdown-menu-item-active{

        background-color: rgba(132, 63, 255,0.4);  // 展开时。默认选中option的背景色
      }
    }
    // 聚焦时 边线颜色为背景色   失焦时蓝色高亮颜色替换成紫色
    .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active{

      border-color: transparent;
      box-shadow: 0 0 2px rgba(132,63,255,1);
    }
  }

  .social-links {
    position: absolute;
    right: 280px;
    color: #333333;

    img {
      width: 36px;
      margin: 0 5px;
    }
  }

  i {
    margin: 0 10px;
    font-size: 32px;
  }

  .lang-item {
    padding: 0 10px;
    height: 30px;
    cursor: pointer;
    display: inline-block;
    color: #CCCCCC;
    font-size: 24px;
    margin-right: 10px;
    &.active {
      color: #FFFFFF;
    }
  }

  .lang-options {
    position: absolute;
    right: 20px;
  }

  .lang-icon {
    cursor: pointer;
    width: 36px;
    margin-right: 20px;
  }
  .topbar {
    width: 100%;
    padding: 10px;
    background: black;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    align-content: center;
    justify-content: space-between;
    height: 70px;
    font-family: BebasNeue-Regular;

    img {
      width: 50px;
      height: 50px;
    }

    .nav-item {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      color: #AAA;
      letter-spacing: 3px;
      height: 50px;
      line-height: 50px;
      margin-left: 20px;
    }

    .connect-btn {
      display: inline-block;
      height: 48px;
      border-radius: 24px;
      border: 1px solid #CCC;
      font-size: 16px;
      letter-spacing: 2px;
      color: white;
      line-height: 48px;
      padding: 0 20px;
      cursor: pointer;
      user-select: none;
    }

    .ant-dropdown-link {
      margin-right: 20px;
      color: white;
      font-size: 18px;
    }
  }

  .header {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    z-index: 2;
    background: rgba(18, 18, 18, 0.5);
    font-family: "BebasNeue-Regular", sans-serif;
    display: flex;
    align-items: center;
    height: 76px;
    padding-left: 2rem;

    .logo {
      margin-right: 40px;
      font-size: 24px;
      letter-spacing: 4px;
      color: #fff;
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-item {
      cursor: pointer;
      float: left;
      width: 74px;
      margin: 0 15px;
      font-size: 16px;
      letter-spacing: 2px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;

      &.not-allow {
        cursor: not-allowed;
      }

      &.active {
        font-weight: 600;
        border-bottom: 2px solid #FFC938;
      }
    }
  }
</style>
