<template>
  <div class="footer">
    <div class="social-links">
      <a href="https://www.instagram.com/cosmochamber.nft/" target="_blank">
        <img src="/img/ins.png">
      </a>
      <a href="https://discord.gg/TJhzDTkNdN" target="_blank">
        <img src="/img/discord.png">
      </a>
      <a href="https://twitter.com/CosmoChamber" target="_blank">
        <img src="/img/twitter.png">
      </a>
    </div>
    <p class="copyright">©2021 double nft labs</p>
    <div class="lab">
      <img src="/img/doublelab.png">
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
    components: {
    },
  }
</script>

<style scoped lang="scss">
  .footer {
    padding: 20px;
    .copyright {
      text-align: center;
      font-size: 16px;
      margin-bottom: 8px;
      color: #333333;
    }

    .social-links {
      text-align: center;
      color: #333333;
      margin-bottom: 1rem;

      img {
        width: 36px;
        margin: 0 15px;
      }
    }

    i {
      margin: 0 10px;
      font-size: 32px;
    }
  }

  .lab {
    text-align: center;
    img {
      width: 100px;
    }
  }
</style>
