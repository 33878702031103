<template>
  <div id="app">
    <Header />
    <div class="main">
      <router-view/>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss">
  body {
    user-select: none;
  }
  @font-face {
    font-family: "BebasNeue-Regular";
    src: url("./assets/font/BebasNeue-Regular.ttf") format('truetype'),
    url("./assets/font/BebasNeue-Regular.ttf") format('truetype');
  }

  @font-face {
    font-family: "Montserrat-Medium";
    src: url("./assets/font/Montserrat-Medium.ttf") format('truetype'),
    url("./assets/font/Montserrat-Medium.ttf") format('truetype');
  }
  #app {
    font-family: Montserrat-Medium, georgia, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 1280px;
  }

  body { margin: 0; padding: 0; }

  .mintLayer {
    .ant-modal-content {
      background: none;
      box-shadow: none;
    }

    .ant-modal-title {
      font-size: 28px;
    }

    .batch-mint-btn {
      background: #FFC938;
      border: none;
      height: 50px;
      line-height: 50px;
      border-radius: 8px;
      font-size: 20px;
      cursor: pointer;
      color: black;
      width: 100%;
      margin-top: 20px;
    }

    .ant-modal-body {
      background: #E9E9E9;
      padding: 20px;
    }
    .ant-modal-header {
      border-radius: 28px 28px 0 0;
      background: #FFC938;
      text-align: center;
    }

    .numberBox {
      box-sizing: border-box;
      margin: 0 auto 0;
      padding: 24px;
      border-radius: 16px;
      background-color: #fff;

      p {
        font-size: 20px;
        color: #131415;
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid #DEDEDE;
      }

      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0 10px;
        strong {
          margin-right: 20px;
          font-size: 20px;
          color: #131415;
        }
        input {
          box-sizing: border-box;
          width: 60px;
          height: 24px;
          margin-right: 20px;
          padding-left: 20px;
          font-size: 20px;
          color: #979797;
          border: 1px solid #979797;
        }
        .box {
          display: flex;
          .sub,.add {
            width: 48px;
            height: 48px;
            text-align: center;
            border: 1px solid #99A5BF;
            font-size: 28px;
            cursor: pointer;
            color: #99A5BF;
          }
          span {
            line-height: 48px;
            text-align: center;
            margin: 0 14px;
            font-size: 28px;
            color: #474758;
          }
        }
      }
    }
  }

  .main {
    margin-top: 146px;
  }

  .hide {
    display: none;
  }
</style>
<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
  export default {
    components: {
      Header,
      Footer,
    }
  }
</script>
