const axios = require('axios')
// const host = 'http://127.0.0.1:9002'
// const host = 'https://api.cosmochamber.art'
const host = 'https://api.cosmochamber.art'
const request = axios.create({
  baseURL: host,
  timeout: 60000,
  headers: { }
})

module.exports = {
  getSignature: async function(address) {
    const { status, data: { code, data, err }} = await request.get(`/wl?address=${address}`)
    return data
  }
}
