<template>
  <div class="home">
    <a-modal
        title="Mint"
        class="mintLayer"
        :visible="mintPopup"
        @ok="mint"
        :bodyStyle="{}"
        :footer="null"
        @cancel="hideMintPopup"
    >
      <template slot="title">
        <div class="mint-title">{{$t('common.mint')}}</div>
      </template>
      <div class="mint-wrap">
        <div class="numberBox">
          <p>{{price}} ETH {{$t('home.mintDesc')}}</p>
          <div class="number">
            <strong>{{$t('common.numbers')}}</strong>
            <input type="hidden" disabled v-model="mintNumber" />
            <span class="box">
              <div class="sub" @click="subMint">-</div>
              <span>{{mintNumber}}</span>
              <div class="add" @click="addMint">+</div>
            </span>
          </div>
          <div>
            <button class="batch-mint-btn" @click="mint">{{$t('home.mintNow')}}</button>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
        title="Mint"
        class="mintLayer"
        :visible="spiritPopup"
        @ok="mintSpirit"
        :bodyStyle="{}"
        :footer="null"
        @cancel="hideSpiritPopup"
    >
      <template slot="title">
        <div class="mint-title">{{$t('common.mint')}}</div>
      </template>
      <div class="mint-wrap">
        <div class="numberBox">
          <p>0.025 ETH {{$t('home.mintSpirit')}}</p>
          <div class="number">
            <strong>{{$t('common.numbers')}}</strong>
            <input type="hidden" disabled v-model="mintSpiritNumber" />
            <span class="box">
              <div class="sub" @click="subSpiritMint">-</div>
              <span>{{mintSpiritNumber}}</span>
              <div class="add" @click="addSpiritMint">+</div>
            </span>
          </div>
          <div>
            <button class="batch-mint-btn" @click="mintSpirit">{{$t('home.mintNow')}}</button>
          </div>
        </div>
      </div>
    </a-modal>
    <div class="banner">
      <img class="bg" src="../assets/home/banner1.jpg">
      <div class="banner-content">
        <p class="title">{{$t('home.banner.title')}}</p>
        <p class="sub-content">{{$t('home.banner.content')}}</p>
      </div>
    </div>
    <div class="space">
      <p class="center cosmo">
        <img src="../assets/cosmob.png">
      </p>
      <p class="sub-content">{{$t('home.p4Content')}}</p>
      <div class="img-wrap">
        <img src="/img/phase4.jpg">
        <div class="mintLayer wl">
          <div class="mint-wrap">
            <div class="numberBox">
              <p>{{totalMint}} {{$t('common.minted')}}</p>
              <div class="number">
                <strong>{{$t('common.numbers')}}</strong>
                <input type="hidden" disabled v-model="mintNumber" />
                <span class="box">
                <div class="sub" @click="subMint">-</div>
                <span>{{mintNumber}}</span>
                <div class="add" @click="addMint">+</div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrap">
        <a-button class="drop-btn active" @click="publicMint">
          {{$t('common.wlMint', { price })}}
        </a-button>
      </div>
      <a-row>
        <a-col :span="14" :offset="5" class="center">
          <div class="clearfix">
            <div class="screen-item">
              <p class="screen-week" :class="{transparent: true, active: true}">{{  $t('home.p1')}}</p>
              <p class="screen-introduction" :class="{transparent: true }">{{ $t('home.p1Content') }}</p>
              <div class="img-wrap">
                <router-link to="/gallery">
                  <img :class="{soldout: true }" src="/img/s1.jpg">
                </router-link>
              </div>
            </div>
            <div class="screen-item">
              <p class="screen-week" :class="{transparent: true, active: true}">{{  $t('home.p2')}}</p>
              <p class="screen-introduction" :class="{transparent: true }">{{ $t('home.p2Content') }}</p>
              <div class="img-wrap">
                <router-link to="/gallery">
                  <img :class="{soldout: true }" src="/img/s2.jpg">
                </router-link>
                <div class="soldout">
                  <p class="state">{{$t('common.soldout')}}</p>
                </div>
              </div>
            </div>
            <div class="screen-item">
              <p class="screen-week" :class="{transparent: true, active: true}">{{  $t('home.p3')}}</p>
              <p class="screen-introduction active" :class="{transparent: true }">{{ $t('home.p3Content') }}</p>
              <div class="img-wrap">
                <router-link to="/gallery">
                  <img :class="{soldout: true }" src="/img/s3.jpg">
                </router-link>
              </div>
            </div>
          </div>
          <div class="activity-description">
            <a href="https://discord.gg/TJhzDTkNdN" target="_blank">
              <img src="/img/discord.png">
            </a>
            <span>{{$t('home.activityContent1')}}</span>
          </div>
          <div class="activity-description">
            <a href="https://twitter.com/CosmoChamber" target="_blank">
              <img src="/img/twitter.png">
            </a>
            <span>{{$t('home.activityContent2')}}</span>
          </div>
        </a-col>
      </a-row>

      <div class="video-wrap">
        <video class="video" src="/assets/cosmochamber.mp4" muted autoplay loop></video>
      </div>
    </div>
    <div class="universe">
      <p class="sub-title">/ {{$t('common.Cosmo')}} /</p>
      <p class="description">{{$t('home.cosmoDesc')}}</p>
      <div class="hxz-wrap">
        <img src="../assets/hanxizai.png">
        <div class="question">?</div>
      </div>
      <div style="text-align: center">
        <router-link to="/gallery">
          <a-button class="drop-btn active gallery-link">{{$t('nav.gallery')}}</a-button>
        </router-link>
      </div>
    </div>
    <a-row class="about">
      <p class="title">{{$t('home.About')}}</p>
      <a-col :span="24">
        <p style="line-height: 28px;width: 500px;margin-left: calc(50% - 200px);" v-html="$t('home.aboutPart1')"></p>
      </a-col>
      <a-col :span="24">
        <div class="artist">
          <img src="../assets/home/artist.png">
        </div>
      </a-col>
    </a-row>
    <a-row class="sale-plan">
      <p class="sub-title">{{$t('home.ROADMAP')}}</p>
      <a-col :span="18" :offset="3">
        <a-col :span="6">
          <div class="sale-item">
            <div class="icon-wrap">
              <img class="sale-icon" src="../assets/home/icon1.png">
            </div>
            <p class="sale-title">{{$t('home.Sales')}}</p>
            <p class="description">{{$t('home.r1')}}</p>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="sale-item">
            <div class="icon-wrap">
              <img class="sale-icon" src="../assets/home/icon2.png">
            </div>
            <p class="sale-title">{{$t('home.Culture')}}</p>
            <p class="description">{{$t('home.r2')}}</p>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="sale-item">
            <div class="icon-wrap">
              <img class="sale-icon" src="../assets/home/icon3.png">
            </div>
            <p class="sale-title">{{$t('home.Grant')}}</p>
            <p class="description">{{$t('home.r3')}}</p>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="sale-item">
            <div class="icon-wrap">
              <img class="sale-icon" src="../assets/home/icon3.png">
            </div>
            <p class="sale-title">{{$t('home.Investor')}}</p>
            <p class="description">{{$t('home.r4')}}</p>
          </div>
        </a-col>
        <a-col :span="24">
          <p class="description2" v-html="$t('home.roadmapContent')"></p>
        </a-col>
      </a-col>
    </a-row>
    <a-row class="team">
      <p class="title">TEAM</p>
      <div class="team-wrap clearfix">
        <a href="https://twitter.com/zoojoo_art" target="_blank">
          <div class="member-wrap">
            <img src="../assets/member/dada.jpg">
            <p class="name">ZOOJOO</p>
            <p class="description">Manager</p>
          </div>
        </a>
        <div class="member-wrap">
          <img src="../assets/member/wayne.png">
          <p class="name">Wayne</p>
          <p class="description">Engineer</p>
        </div>
        <div class="member-wrap">
          <img src="../assets/member/armotor.png">
          <p class="name">ARMOTOR</p>
          <p class="description">Artist</p>
        </div>
        <div class="member-wrap">
          <img src="../assets/member/GOO.png">
          <p class="name">GA</p>
          <p class="description">Artist</p>
        </div>
        <div class="member-wrap">
          <img src="../assets/member/zero.jpg">
          <p class="name">MetaZ</p>
          <p class="description">Project metaverse consultant</p>
        </div>
        <div class="member-wrap">
          <img src="../assets/member/leo.png">
          <p class="name">Leo</p>
          <p class="description">Operation</p>
        </div>
        <div class="member-wrap">
          <img src="../assets/member/tong.png">
          <p class="name">Tong</p>
          <p class="description">Artist</p>
        </div>
        <div class="member-wrap">
          <img src="../assets/member/contactus.jpg">
          <p class="name">1/1</p>
          <p class="description">Mystery man</p>
        </div>
      </div>
    </a-row>
    <a-row class="partner">
      <p class="title">/ {{$t('common.partner')}} /</p>
      <a-col :span="18" :offset="3" class="partner-wrap">
        <div style="text-align: center">
          <img src="/img/partner/herrgallery.jpg" style="margin: 40px 10px 0;">
          <div>
            <img style="width: 100px;margin: 10px;" src="/img/partner/herr.png">
          </div>
          <div>
            <img style="width: 500px;max-width: 400px;margin: 10px;" src="/img/partner/herr_desc.png">
          </div>
        </div>
        <img src="/img/partner/polygon.png">
        <a href="https://opensea.io/assets/cosmochamber" target="_blank">
          <img src="../assets/home/partner/opensea.svg">
        </a>
        <img src="/img/partner/maxon.png" style="width: 100px;">
        <img src="/img/partner/jiahe.png" style="width: 100px;">
        <img src="/img/partner/seedao.png" style="width: 100px;">
        <a href="https://www.element.market/collections/cosmochamber" target="_blank">
          <img src="../assets/home/partner/element.svg">
        </a>
        <img src="/img/partner/M50.png" style="width: 80px;">
        <img src="/img/partner/NFTcalendar.png">
        <img src="/img/partner/HUACHENAUCTIONS.png">
        <img src="/img/partner/postmasters.png" style="width: 160px;">
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import BigNumber from "bignumber.js";
  import { Icon, message } from 'ant-design-vue'
  import {mapState} from "vuex";
  import api from '../utils/api'

  export default {
    name: 'Home',
    components: {},
    data() {
      return {
        gasPrice: 0,
        balance: 0,
        totalMint: 0,
        isEnable: null,
        loading: null,
        isLoading: false,
        fullPage: true,
        mintNumber: 1,
        userMint: 0,
        mintLimit: 10,
        wlLimit: 3,
        wlPrice: 0.08,
        price: 0.1,
        roomBalance: 0,
        timer: null,
        mintPopup: false,
        mintSpiritNumber: 10,
        spiritPopup: false,
        mintVisible: false,
        screens: [{title: this.$t('home.p1'),description: this.$t('home.p1Content'),price: '0.03',image: '/img/s1.jpg',state: this.$t('common.soldout'),subImage: '/img/cat.png',}, {title: this.$t('home.p2'),description: this.$t('home.p2Content'),price: '0.08',state: this.$t('common.soldout'),image: '/img/s2.jpg',subImage: '/img/pumpkin.png',}, {title: this.$t('home.p3'),description: this.$t('home.p3Content'),rate: 4,rarible: 'Ultra Rare',price: '0.20',image: '/img/s3.jpg',state: this.$t('common.ongoing'),subImage: '/img/3.png',}, {title: this.$t('home.p4'),description: this.$t('home.p4Content'),rate: 4,rarible: 'Epic',price: '0.50',image: '/img/s4.jpg',state: this.$t('common.yetstart'),subImage: '/img/candle.png',}],
        sprits: [{rate: 3,image: '/img/cat.png',active: 2,}, {rate: 4,image: '/img/pumpkin.png',active: 1,}, {rate: 4,active: 0,image: '/img/Tu-Fire-Demon.png',}, {rate: 4,active: 0,image: '/img/empty_room.png',}]
      }
    },

    mounted() {
      this.$store.commit('switchNavi', 0)
      const _this = this
      this.timer = setTimeout(async function() {
        if (_this.nftContract) {
          _this.totalMint = await _this.nftContract.methods.currentNFTid().call()
        }
        _this.gasPrice = await _this.web3.eth.getGasPrice()
        if (_this.account) {
          _this.balance = await _this.web3.eth.getBalance(_this.account) / 10**18
        }
        clearInterval(_this.timer)
      }, 500)
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    computed: {
      ...mapState(['account', 'web3', 'networkid', 'nav',
        'nftContract', 'itemContract', 'saleContract', 'mintData',
      ]),
    },
    methods: {
      subSpiritMint() {
        if (this.mintSpiritNumber <= 1) {
          return;
        }
        this.mintSpiritNumber--;
      },
      addSpiritMint() {
        this.mintSpiritNumber++;
      },
      hideSpiritPopup() {
        this.spiritPopup = false
      },
      showSpiritPopup() {
        this.spiritPopup = true
      },

      subMint() {
        if (this.mintNumber <= 1) {
          return;
        }
        this.mintNumber--;
      },
      addMint() {
        if (this.mintNumber < this.mintLimit) {
          this.mintNumber++;
        }
      },
      hideMintPopup() {
        this.mintPopup = false
      },
      showMintPopup() {
        this.mintPopup = true
      },

      async publicMint() {
        message.destroy()
        if (this.mintNumber <= 0) {
          return
        }

        const _this = this
        // 獲取可以 mint 數量
        const totalPrice = new BigNumber(this.price).times(this.mintNumber)
        let gasPrice = this.gasPrice
        let balance = this.balance
        if (!gasPrice) {
          gasPrice = await this.web3.eth.getGasPrice()
        }

        if (!balance) {
          balance = await this.web3.eth.getBalance(this.account) / 10**18
        }

        if (balance < totalPrice.toNumber()) {
          return message.error(this.$t('tips.fundNotEnough'))
        }

        let loadingItem = null

        if (!this.isLoading) {
          loadingItem = this.$loading.show({
            container: null,
            canCancel: false,
            color: '#ee7e33',
            width: 140,
            height: 140,
            onCancel: function() {},
          })
        }

        const gasUsed = [0,122004,132072,159240,186408,213576,240744,267912,295080,322248,349416,376584,403752,430920,458088,485256,512424,539592,566760,593928,621096]
        let gasLimit = gasUsed[this.mintNumber]

        const params = {
          from: this.account,
          gasPrice,
          gas: gasLimit,
          value: totalPrice.times(10**18)
        }

        if (gasLimit) {
          params.gas = gasLimit
        }
        this.saleContract.methods.publicMint(this.mintNumber).send(params, function(err, hash) {
          // _this.hideMintPopup()
          if (err) {
            loadingItem.hide()
            _this.isLoading = false
            _this.handleError(err)
          }
        }).on('receipt', function(receipt) {
          loadingItem.hide()
          _this.isLoading = false
          message.success('Mint success')
        })
      },

      handleError(err) {
        if (err.code === 4001) {
          message.error(this.$t('tips.cancelTx'))
        } else if (err.code === -32603) {
          message.error(err.message.replace('Error: execution reverted: ', ''))
        } else {
          let errorMessage = ''
          if (err.message) {
            errorMessage = err.message.replace('Error: execution reverted: ')
          } else if (typeof err === 'Object') {
            errorMessage = JSON.stringify(err)
          } else {
            errorMessage = err
          }

          message.error(errorMessage)
        }
      },

      async mintSpirit() {
        if (this.itemContract) {
          const gasPrice = await this.web3.eth.getGasPrice()
          const price = 0.025
          const totalPrice = price * this.mintSpiritNumber
          const balance = await this.web3.eth.getBalance(this.account) / 10**18
          if (balance < totalPrice) {
            return message.error(this.$t('tips.fundNotEnough'))
          }
          const gasLimit = await this.itemContract.methods.mint(this.mintSpiritNumber).estimateGas({
            from: this.account,
            value: totalPrice * 10**18,
          })

          const _this = this
          this.itemContract.methods.mint(this.mintSpiritNumber).send({
            from: this.account,
            value: totalPrice * 10**18,
            gas: gasLimit,
            gasPrice,
          }, function(err, hash) {
            _this.hideSpiritPopup()
          }).on('transactionHash', function(hash) {
            // console.log(hash)
          })
        }
      },
      showMint() {
        this.mintVisible = true
      },
      hideMint() {
        this.mintVisible = false
      },
      async mint() {
        if (!this.account) {
          return message.error(this.$t('tips.unlockWallet'))
        }
        if (this.nftContract) {
          const totalPrice = this.price * this.mintNumber
          const gasPrice = await this.web3.eth.getGasPrice()
          const balance = await this.web3.eth.getBalance(this.account) / 10**18

          if (balance < totalPrice) {
            return message.error(this.$t('tips.fundNotEnough'))
          }

          const gasLimit = await this.nftContract.methods.buyNFT(this.mintNumber).estimateGas({
            from: this.account,
            gasPrice,
            value: totalPrice * 10**18
          })

          const _this = this
          this.nftContract.methods.buyNFT(this.mintNumber).send({
            from: this.account,
            gasPrice,
            gas: gasLimit,
            value: totalPrice * 10**18
          }, function(err, hash) {
            _this.hideMintPopup()
          })
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  @import "../assets/base.scss";
  .home {
    .video-wrap {
      text-align: center;
    }

    .mintLayer.wl {
      width: 358px;
      position: absolute;
      left: 1px;
      bottom: 1px;

      .numberBox p {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    .video {
      width: 100%;
      max-width: 960px;
      margin: 80px auto;
    }
    .sub-title, .subtitle {
      color: #000;
    }
    .center {
      text-align: center;
    }

    .announcement {
      color: black;
      padding-top: 80px;
      margin-bottom: 60px;
      font-size: $fontLarge;
      font-weight: bold;
      text-align: center;
    }

    .banner {
      position: relative;

      .logo {
        //position: absolute;
        //top: 5%;
        //right: 5%;
        width: 50%;
      }

      .bg {
        width: 100%;
      }

      .banner-content {
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;

        .title {
          width: 480px;
          font-size: $fontMiddle;
          font-weight: 600;
          color: #000000;
          margin-bottom: 0;
        }

        .sub-content {
          font-size: $fontSmall;
          margin-top: 10px;
          color: #000;
        }
      }

      .steps {
        z-index: 1;
        position: absolute;
        width: 550px;
        height: 550px;
        right: 274px;
        background: #FEDB7F;
        bottom: -175px;
        border-radius: 424px;
        text-align: center;

        .date {
          font-size: $fontSmall;
          color: #000;
          margin: 10px;
        }

        .title {
          font-size: $fontMiddle;
          line-height: 30px;
          font-weight: bold;
          color: #000;
          padding: 0 100px;
          margin-top: 30px;
          margin-bottom: 10px;
        }

        .description {
          font-size: $fontSmall;
          width: 300px;
          margin: 0 auto;
          text-align: left;
          font-weight: 500;
          color: #333333;
        }

        .mint-button {
          text-align: center;
          margin-top: -10px;
          line-height: 40px;
          background: #FF750D;
          border-radius: 12px;
          border: none;
          font-size: 18px;
          width: 170px;
          height: 46px;
          color: #131415;

          &.grey {
            background: #d8d8d8;
          }
        }

        .mint-image {
          width: 100px;
          margin: auto;
          height: 80px;
          visibility: hidden;
          text-align: center;
          img {
            width: 100%;
          }
        }
      }

      .step1 {
        padding-top: 40px;
        font-size: $fontMiddle;
      }

      .step2 {
        display: none;
        z-index: 1;
        position: absolute;
        width: 550px;
        height: 550px;
        right: 80px;
        background: #FFF6E0;
        bottom: -275px;
        border-radius: 424px;
        text-align: center;

        .date {
          font-size: $fontSmall;
          color: #000;
          margin-top: 30px
        }

        .title {
          font-size: $fontLarge;
          line-height: $fontLarge;
          color: #000;
          font-weight: 400;
        }

        .description {
          font-size: 16px;
          width: 300px;
          margin: 0 auto;
          text-align: left;
          font-weight: 500;
          color: #333333;
        }

        .mint-button {
          margin-top: 16px;
          width: 120px;
          margin-left: -40px;
          padding: 0 20px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #FF750D;
          background: none;
          border-radius: 8px;
          background: white;
          font-size: $fontSmall;
          color: #FF750D;
        }

        .mint-image {
          width: 25%;
          margin: 0 auto;
          text-align: center;
          img {
            width: 100%;
          }
        }
      }
    }

    .artist {
      margin-top: 50px;
    }

    .space {
      .img-wrap {
        position: relative;
        width: 360px;
        margin: auto;
      }

      .activity-description {
        display: flex;
        width: 500px;
        align-items: center;
        span { font-size: 18px;}
        img { width: 36px; margin-right: 20px}
        text-align: left;
        margin: 10px auto;
      }

      .cosmo {
        padding-top: 80px;
        img {
          width: 200px;
        }
      }

      .screen-item {
        width: 33%;
        padding: 10px;
      }

      .sub-content {
        font-size: $fontSmall;
        margin-top: 10px;
        color: #000;
        text-align: center;
      }

      .img-wrap {
        text-align: center;
      }

      .btn-wrap {
        margin-top: -30px;
        margin-bottom: 40px;
        text-align: center;
        button {
          cursor: pointer;
          border-radius: 10px;
          width: 360px;
          font-size: 20px;
          height: 60px;
          background: #FF750D;
        }
      }

      margin-top: 0;
      .title {
        font-size: $fontMiddle;
        color: #606060;
        //margin-top: 90px;
        margin-bottom: 10px;
        text-align: center;
      }

      .description {
        font-size: 14px;
        color: #000000;
        text-align: center;
        margin-bottom: 30px;
      }

      .screen-week {
        font-size: 24px;
        text-align: center;
        width: 100px;
        margin: 0 auto 5px;
        box-sizing: border-box;
        &.active {
          border-bottom: 2px solid orange;
        }
      }

      .screen-introduction {
        font-size: $fontSmall;
        height: 60px;
        width: 80%;
        margin-left: 10%;
        text-align: center;
        margin-bottom: 10px;
        color: #000;
        font-weight: 400;
      }
    }
    .soldout {
      //background: url("../assets/soldout.png");
    }

    .rate {
      text-align: center;
      margin: 10px 0;

      i {
        font-size: $fontMiddle;
        margin: 20px 3px 0;
      }

      .light {
        color: #FFC938;
      }
    }

    .rare-text, .price {
      text-align: center;
      color: #000000;
    }

    .rare-text {
      font-size: $fontMiddle;
      font-weight: 500;

      &.red {
        color: red;
      }
    }

    .price {
      font-size: $fontMiddle;
    }

    .sub-title {
      text-align: center;
      font-size: $fontLarge;
      margin-top: 40px;
    }
  }

  .screen-item {
    float: left;
    width: 25%;
    text-align: center;
    position: relative;
    padding: 0;

    .img-wrap {
      text-align: center;
    }

    .state {
      font-size: $fontMiddle;
      line-height: 40px;
      height: 46px;
      text-align: center;
      width: 180px;
      margin: 0 auto;
      box-sizing: border-box;
      &.bordered {
        border: 1px solid grey;
        border-radius: 4px;
      }
    }

    img {
      width: 100%;
      &.soldout {
        opacity: .5;
      }
    }
  }

  .combine-item {
    float: left;
    width: 25%;
    position: relative;
    text-align: center;
    margin: 0 12.5%;

    .btn-wrap {
      position: relative;
      img {
        width: 100%;
      }

      .img-wrap {
        position: relative;

        .drop-btn {
          cursor: pointer;
          font-weight: bold;
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          bottom: 22%;
        }
      }
    }

    .new-item {
      width: 50%;
      margin: 0 25%;
    }
  }

  .sprit {
    .sub-img-wrap {
      text-align: center;

      img {
        width: 50% !important;
      }
    }

    .description {
      font-weight: 400;
      color: #000;
      font-size: $fontMiddle;
    }
  }

  .drop-btn {
    cursor: pointer;
    width: 146px;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #D8D8D8;
    border-radius: $fontLarge;
    box-sizing: border-box;
    border: 1px solid #979797;
    font-weight: 500;
    &:hover {
      color: #000;
      border: 1px solid #979797;
    }

    &.active {
      background: #FFC938;
      color: #000;
      font-weight: 600;
      border: none;
    }
  }

  .universe {
    display: none;
    .description {
      text-align: center;
      color: #000;
      font-size: $fontMiddle;
    }

    .hxz-wrap {
      text-align: center;
      max-width: 1200px;
      margin: 0 auto;
      background: #f3eee7;
      padding: 20px;
      position: relative;

      .question {
        display: inline-block;
        font-size: $fontLarge;
        color: white;
        font-weight: bold;
        position: absolute;
        top: calc(50% - 30px);
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
      }

      img {
        width: 50%;
        max-width: 800px;
      }
    }
  }

  .gallery-link {
    border-radius: 5px;
    margin-top: 20px;
    font-weight: initial;
  }

  .about {
    margin-top: 40px;
    background: #000;
    color: white;

    .title {
      font-size: $fontLarge;
      margin: 30px;
      text-align: center;
    }

    img {
      width: 100%;
      padding: 0 20px;
    }

    .subtitle {
      color: #FCC737;
      font-size: $fontMiddle;
    }

    .btn-wrap {
      text-align: center;
      margin: 20px;

      button {
        border-radius: 5px;
      }
    }
  }

  .sale-plan {
    .icon-wrap {
      height: 50px;
      margin-bottom: 30px;
    }

    .sale-icon {
      width: 50%;
      max-width: 50px;
    }

    .sale-item {
      padding: 0 30px;
      text-align: center;
    }

    .sale-title {
      font-size: $fontMiddle;
      color: #000;
      font-weight: 500;
    }

    .description {
      margin-left: 15%;
      width: 70%;
      margin-top: 50px;
      font-size: $fontSmall;
      line-height: $fontMiddle;
      font-weight: 500;
    }

    .description2 {
      margin-left: calc(50% - 190px);
      width: 380px;
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: $fontSmall;
      line-height: 28px;
      font-weight: 500;
    }

    .btn-wrap {
      text-align: center;
      margin: 20px;

      button {
        border-radius: 5px;
      }
    }
  }

  .grant {
    background: #104B2F;

    .grant-title {
      font-size: $fontLarge;
      color: white;
      font-weight: 500;
      text-align: center;
      margin: 40px 0 20px;
    }

    .description {
      width: 70%;
      max-width: 1200px;
      color: white;
      font-size: $fontSmall;
      font-weight: 500;
      line-height: 30px;
      margin: 30px auto;
    }

    .img-wrap {
      text-align: center;

      img {
        width: 50%;
      }
    }

    .subtitle {
      color: #FCC737;
      font-size: $fontMiddle;
      font-weight: 500;
    }

    .content {
      color: white;
      line-height: $fontMiddle;
    }
  }

  .roadmap {
    .title {
      margin: 40px 0 80px;
      color: #000;
      font-size: $fontLarge;
      text-align: center;
    }

    .nft-img {
      img {
        width: 100%;
      }
    }

    .subtitle {
      font-size: $fontMiddle;
      margin: 20px 0;
    }

    .content {
      font-size: $fontSmall;
      line-height: 40px;
      margin-bottom: 40px;
    }
  }

  .team {
    background: #000;

    .description {
      height: 46px;
    }

    .title {
      margin: 40px 0 80px;
      color: white;
      font-size: $fontMiddle;
      text-align: center;
    }

    .team-wrap {
      width: 860px;
      text-align: center;
      margin: 0 auto;
    }

    .member-wrap {
      float: left;
      width: 200px;
      margin: 0 43px ;
      display: inline-block;
      padding: 20px;
      img {
        width: 100%;
      }
    }

    .name, .description {
      color: white;
    }

    .name {
      margin-bottom: 10px;
      margin-top: 10px;
      font-size: $fontMiddle;
      font-weight: 500;
    }
  }

  .preview-wrap {
    width: 120px;
    height: 200px;
    margin-top: 100px;
    margin-right: 30px;
    color: #000;
    font-weight: bold;
    float: right;

    p {
      margin-top: 100px;
    }
    img {
      width: 170px;
      margin-left: -40px;
    }
  }

  .partner {
    .title {
      font-size: $fontLarge;
      color: #000;
      margin-top: 90px;
      margin-bottom: 10px;
      text-align: center;
    }

    .partner-wrap {
      img {
        max-width: 250px;
        margin: 40px;
      }
    }
  }

  .gallery-btn {
    margin-top: 60px;
  }

  .transparent {
    opacity: .3;
  }
</style>
