<template>
  <div class="punk-wrap">
    <router-link to="/">
      <div class="banner">
        <img src="../assets/punk/banner.jpg">
      </div>
    </router-link>
    <div class="main">
      <div class="title">
        <img src="../assets/punk/logo.png">
      </div>
      <div class="infoBox">
        <p>See your punk in our chamber. A unique NFT reserved for cryptopunk owners.</p>
      </div>
      <div class="punk-case">
        <div class="case-item">
          <img src="../assets/home/punk7521.png">
          <p>#7521</p>
        </div>
        <div class="case-item">
          <img src="../assets/home/punk7523.png">
          <p>#7523</p>
        </div>
      </div>
      <div class="btnBox clearfix">
        <a-col :span="12" :offset="6" class="punk-id">
          <span>#</span>
          <a-input v-model="punkIndex" placeholder="Your punk ID" />
        </a-col>
        <a-col :span="12" :offset="6">
          <button type="button" class="claim" v-if="isClaim" @click="claim">Mint</button>
          <button type="button" disabled v-else>Sorry, no Cryptopunk in your wallet.</button>
        </a-col>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "vip",
  data() {
    return {
      isClaim: true,
      punkIndex: null,
      timer: null
    }
  },
  computed: {
    ...mapState(['account', 'web3', 'networkid', 'nav', 'punkContract', 'crossContract']),
  },
  async mounted() {
    this.$store.commit('switchNavi', -1)
    await this.checkWhiteList()

    const _this = this
    this.timer = setInterval(async () => {
      await _this.checkWhiteList()
    }, 2000)

  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    async checkWhiteList() {
      if (this.punkContract && this.account) {
        const balance = await this.punkContract.methods.balanceOf(this.account).call()
        this.isClaim = Number(balance) > 0
        clearInterval(this.timer)
        this.timer = null
      }
    },

    async claim() {
      if (!this.punkIndex) {
        return
      }

      if (Number.isNaN(Number(this.punkIndex))) {
        return
      }

      const owner = await this.punkContract.methods.punkIndexToAddress(this.punkIndex).call()
      if (owner.toLowerCase() !== this.account.toLowerCase()) {
        return alert('Not own this NFT')
      }
      if (this.isClaim) {
        const gasPrice = await this.web3.eth.getGasPrice()
        try {
          const gasLimit = await this.crossContract.methods.mintByMarket(this.punkIndex, 0).estimateGas({
            from: this.account,
            value: 0,
          })
          this.crossContract.methods.mintByMarket(this.punkIndex, 0).send({
            from: this.account,
            gasPrice,
            gas: gasLimit,
            value: 0,
          }, function(err, hash) {

          }).on('receipt', function(receipt) {
            // location.href = `https://etherscan.io/tx/${hash}`
            const nftid = receipt.events.CrossMinted.returnValues.nftid
          })
        } catch(err) {
          console.log(err)
          alert('Something wrong')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .ant-input {
    height: 40px;
    font-size: 24px;
  }

  .banner {
    img {
      width: 100%;
    }
  }

  .case-item {
    display: inline-block;
    p {
      font-size: 14px;
      font-weight: bold;
      margin-top: -40px;
    }
  }

  .punk-id {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 300px;
    }

    span {
      font-size: 40px;
      margin-right: 10px;
    }
  }

  .punk-case {
    img {
      width: 260px;
    }
  }

  .punk-wrap {
    margin: 0 auto;
    text-align: center;

    .title {
      margin-top: -100px;
      text-align: center;
      font-size: 44px;
      color: #000;
      margin-bottom: 20px;
      img {
        width: 140px;
      }
    }

    .btnBox {
      text-align: center;
      margin-top: 20px;
      button {
        cursor: pointer;
        width: 400px;
        height: 50px;
        font-size: 20px;
        color: #111;
        border: 0;
        background: #E0E0E0;
        border-radius: 10px;
      }
      button.claim {
        background-color: #FFC938;
      }
    }
    .infoBox {
      margin: 10px auto 0;
      p {
        font-size: 20px;
        color: #131415;
        text-align: center;
      }
    }
    .imgBox {
      text-align: center;
      img {
        width: 750px;
      }
    }
  }

  .ant-input:hover,
  .ant-input:focus {
    border: 1px solid #d9d9d9;
    box-shadow: none;
  }
</style>
