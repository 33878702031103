<template>
  <div class="puzzleBox">
    <a-modal
        :title="$t('common.mint')"
        class="mintLayer"
        :visible="mintPopup"
        @ok="_mint"
        :bodyStyle="{}"
        :footer="null"
        @cancel="hideMint"
    >
      <template slot="title">
        <div class="mint-title">{{$t('common.mint')}}</div>
      </template>
      <div class="mint-wrap">
        <div class="numberBox">
          <p>0.025 ETH {{$t('home.mintSpirit')}}</p>
          <div class="number">
            <strong>{{$t('common.numbers')}}</strong>
            <input type="hidden" disabled v-model="mintNumber" />
            <span class="box">
              <div class="sub" @click="subMint">-</div>
              <span>{{mintNumber}}</span>
              <div class="add" @click="addMint">+</div>
            </span>
          </div>
          <div>
            <button class="batch-mint-btn" @click="_mint">{{$t('home.mintNow')}}</button>
          </div>
        </div>
      </div>
    </a-modal>
    <div>
      <img src="../assets/cosmo.png" class="logo">
    </div>
    <div>
      <img v-if="this.$i18n.locale === 'zh'" src="/img/combine/combine_zh.png" class="puzzle">
      <img v-else src="/img/combine/combine_en.png" class="puzzle">
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Puzzle",
  computed: {
    ...mapState(['account', 'web3', 'networkid', 'nav', 'nftContract', 'itemContract']),
  },

  data() {
    return {
      mintNumber: 10,
      mintPopup: false,
    }
  },
  methods: {
    subMint() {
      if (this.mintNumber <= 0) {
        return;
      }
      this.mintNumber--;
    },
    addMint() {
      this.mintNumber++;
    },
    hideMint() {
      this.mintPopup = false
    },
    mint() {
      this.mintPopup = true
    },
    async _mint() {
      if (this.itemContract) {
        const gasPrice = await this.web3.eth.getGasPrice()
        const price = 0.025 * 10**18
        const gasLimit = await this.itemContract.methods.mint(this.mintNumber).estimateGas({
          from: this.account,
          value: price * this.mintNumber,
        })

        this.itemContract.methods.mint(this.mintNumber).send({
          from: this.account,
          value: price * this.mintNumber,
          gas: gasLimit,
          gasPrice,
        }, function(err, hash) {
          // console.log(err, hash)
        }).on('transactionHash', function(hash) {
          // console.log(hash)
        })
      }
    }
  },

  mounted() {
    this.$store.commit('switchNavi', 3)
  }
}
</script>

<style lang="scss" scoped>
  .puzzleBox {
    padding-top: 50px;
    text-align: center;

    .logo {
      width: 200px;
      margin-bottom: 40px;
    }

    .puzzle {
      width: 400px;
      margin-bottom: 40px;
      cursor: pointer;
    }
  }
</style>
