import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Gallery from '../views/Gallery.vue'
import Whitelist from '../views/Whitelist.vue'
import Puzzle from '../views/Puzzle.vue'
import Grant from '../views/Grant.vue'
import Vip from '../views/Vip.vue'
import Punk from '../views/Punk.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery,
  }, {
    path: '/vip',
    name: 'Vip',
    component: Vip,
  }, {
    path: '/grant',
    name: 'Whitelist',
    component: Whitelist,
  }, {
    path: '/whitelist',
    name: 'Whitelist',
    component: Whitelist,
  }, {
    path: '/puzzle',
    name: 'Puzzle',
    component: Puzzle,
  },
  {
    path: '/grant',
    name: 'Grant',
    component: Grant,
  },
  {
    path: '/vip',
    name: 'Vip',
    component: Vip,
  }, {
    path: '/punk',
    name: 'Punk',
    component: Punk,
  }, {
    path: '/cryptopunk',
    name: 'Punk',
    component: Punk,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
