<script>
import Web3 from 'web3'
import {mapMutations, mapState} from 'vuex'
import api from "../utils/api";
export default {
  props: {
    userMessage:{
      type: String,
      default: "null"
    }
  },
  computed: {
    ...mapState(['networkid', 'chainid']),
  },
  data() {
    return {
      mintData: null,
      web3: null,
      MetaMaskAddress: "",    // user Address
      Web3Interval: null,
      AccountInterval: null,
      NetworkInterval: null,
      stateLog: null,
      isComplete: false,
      type: "INIT",
      networks: {
        '1': 'MAINNET',
        '3': 'ROPSTEN',
        '4': 'RINKEBY',
        '5': 'GOERLI',
        '42': 'KOVAN',
        '5777': 'GANACHE',
      },
      MetamaskMsg:{
        LOAD_MATAMASK_WALLET_ERROR: 'Loading metamask error, please try later',
        EMPTY_METAMASK_ACCOUNT: 'Please log in to your metamask to continue with this app.',
        NETWORK_ERROR: 'The connection is abnormal, please try again',
        METAMASK_NOT_INSTALL: 'Please install metamask for this application',
        METAMASK_TEST_NET: 'Currently not in the main network.',
        METAMASK_MAIN_NET: 'Currently Main network',
        USER_DENIED_ACCOUNT_AUTHORIZATION: 'User denied account authorization',
      }
    };
  },
  methods: {
    ...mapMutations(['setAccount', 'setWeb3', 'setEthereum']),
    checkWeb3() {
      let web3 = window.web3;
      if (typeof web3 === 'undefined') {
        this.web3 = null;
        this.Log(this.MetamaskMsg.METAMASK_NOT_INSTALL, "NO_INSTALL_METAMASK");
      }
    },
    async checkAccounts() {
      if (this.web3 === null) return;
      const _store = this.$store
      await window.ethereum.enable()
      const accounts = await this.web3.eth.getAccounts()

      if (accounts.length === 0) {
        this.MetaMaskAddress = "";
        this.Log(this.MetamaskMsg.EMPTY_METAMASK_ACCOUNT, 'NO_LOGIN');
        _store.commit('setAccount', null)
        return;
      }
      this.MetaMaskAddress = accounts[0];
      _store.commit('setAccount', this.MetaMaskAddress)
    },
    async getSignature(account) {
      const res = await api.getSignature(account)
      return res
    },
    async checkNetWork() {
      const _this = this
      if (!this.web3) return
      const netID = await this.web3.eth.net.getId()

      this.netID = netID;

      if (netID !== _this.networkid) {
        // TODO preset config
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x'+this.chainid.toString(16) }],
        });
        return
      }

      if( this.MetaMaskAddress !== '' && netID === 1) return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, 'MAINNET');
      if( this.MetaMaskAddress !== '' && netID === 3) return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, 'ROPSTEN');
      if( this.MetaMaskAddress !== '' && netID === 42) return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, 'LOVAN');
      if( this.MetaMaskAddress !== '' && netID === 4) return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, 'RINKEBY');
      if( this.MetaMaskAddress !== '') this.Log(this.MetamaskMsg.METAMASK_MAIN_NET, "MAINNET");
    },
    Log(msg, type=""){
      const letType = type;
      if(letType === this.type) return;
      const message = this.userMessage === "null" ? msg : this.userMessage;
      this.type = type;
      this.$emit("onComplete", {
        web3: this.web3,
        type,
        metaMaskAddress: this.MetaMaskAddress,
        message,
        netID: this.netID,
      });
    },
    async web3TimerCheck(web3){
      this.web3 = web3;
      const _this = this
      _this.checkAccounts()
      _this.checkNetWork()

      try {
        const blockNumber = await web3.eth.getBlockNumber()
        _this.$store.commit('setBlockNumber', blockNumber)
      } catch(err) {
        console.log(err)
      }
    }
  },
  async mounted() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      this.web3 = window.web3
      this.setEthereum(window.ethereum)

      window.ethereum.on('accountsChanged', function (accounts) {
        location.reload()
      })
      try {
        await window.ethereum.enable();
        this.web3TimerCheck(window.web3);
      } catch (error) {
        this.Log(this.MetamaskMsg.USER_DENIED_ACCOUNT_AUTHORIZATION, "USER_DENIED_ACCOUNT_AUTHORIZATION");
      }
    } else if (window.web3) {
      window.web3 = new window.web3(window.web3.currentProvider);
      this.web3 = window.web3
      this.web3TimerCheck(window.web3);
    } else {
      this.web3 = null;
    }
    this.setWeb3(this.web3)
  }
};
</script>
<template>
  <div id="vue-metamask"></div>
</template>
<style scoped>
#vue-metamask{
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
</style>
