<template>
  <div class="gallery" id="gallery">
    <a-modal
        title="Mint"
        class="mintLayer"
        :visible="mintPopup"
        @ok="_mint"
        :bodyStyle="{}"
        :footer="null"
        @cancel="hideMint"
    >
      <template slot="title">
        <div class="mint-title">{{$t('common.mint')}}</div>
      </template>
      <div class="mint-wrap">
        <div class="numberBox">
          <p>0.025 ETH {{$t('home.mintSpirit')}}</p>
          <div class="number">
            <strong>{{$t('common.numbers')}}</strong>
            <input type="hidden" disabled v-model="mintNumber" />
            <span class="box">
              <div class="sub" @click="subMint">-</div>
              <span>{{mintNumber}}</span>
              <div class="add" @click="addMint">+</div>
            </span>
          </div>
          <div>
            <button class="batch-mint-btn" @click="_mint">{{$t('home.mintNow')}}</button>
          </div>
        </div>
      </div>
    </a-modal>
    <a-row>
      <a-col :span="22" :offset="1">
        <div class="gallery-logo">
          <img src="../assets/cosmo.png">
        </div>
        <div class="art-preview">
          <img src="../assets/demo1.jpg">
          <img src="../assets/demo2.jpg">
          <img src="../assets/demo3.jpg">
        </div>
        <div class="split"></div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="22" :offset="1">
        <h2 class="sub-title">{{$t('gallery.spiritItem')}}</h2>
        <p class="description">{{$t('gallery.title')}}</p>
      </a-col>
      <a-col :span="22" :offset="1" class="nft-item">
        <a-col :span="4" class="img">
          <img src="../assets/nft-items/cat.png">
        </a-col>
        <a-col :span="12" class="nft-description" :offset="2">
          {{$t('gallery.mirrorcat')}}
        </a-col>
        <a-col :span="4" class="btn-wrap">
          <a-button type="primary" class="mint-button" size="large">{{$t('common.soldout')}}</a-button>
        </a-col>
      </a-col>
      <a-col :span="22" :offset="1" class="nft-item">
        <a-col :span="4" class="img">
          <img src="../assets/nft-items/pumpkin.png">
        </a-col>
        <a-col :span="12" class="nft-description" :offset="2">
          {{$t('gallery.pumpkin')}}
        </a-col>
        <a-col :span="4" class="btn-wrap">
          <a-button type="primary" class="mint-button" size="large">{{$t('common.soldout')}}</a-button>
        </a-col>
      </a-col>
      <a-col :span="22" :offset="1" class="nft-item">
        <a-col :span="4" class="img">
          <img src="../assets/nft-items/3.png">
        </a-col>
        <a-col :span="12" class="nft-description" :offset="2">
          {{$t('gallery.floralspirit')}}
        </a-col>
        <a-col :span="4" class="btn-wrap">
          <a-button type="primary" class="mint-button" size="large">{{$t('common.ComingSoon')}}</a-button>
        </a-col>
      </a-col>
      <a-col :span="22" :offset="1" class="nft-item border-none">
        <a-col :span="4" class="img">
          <img src="../assets/nft-items/candle.png">
        </a-col>
        <a-col :span="12" class="nft-description" :offset="2">
          {{$t('gallery.vase')}}
        </a-col>
        <a-col :span="4" class="btn-wrap">
          <a-button type="primary" class="mint-button" size="large">{{$t('common.ComingSoon')}}</a-button>
        </a-col>
      </a-col>
    </a-row>
    <a-row class="screen-wrap">
      <a-col :span="22" :offset="1">
        <h2 class="sub-title">{{$t('gallery.screen')}}</h2>
        <p class="description">{{$t('gallery.screenTitle')}}</p>
      </a-col>
      <a-col :span="24" class="screen-item">
        <a-col :span="6" :offset="1" class="img">
          <img src="/img/p0.png">
        </a-col>
        <a-col :span="16" :offset="1">
          <p class="screen-title">{{$t('gallery.blankScreen')}}</p>
          <p class="screen-description">{{$t('gallery.blankScreenContent')}}</p>
        </a-col>
      </a-col>
      <a-col :span="24" class="screen-item">
        <a-col :span="6" :offset="1" class="img">
          <img src="/img/p2.png">
        </a-col>
        <a-col :span="16" :offset="1">
          <p class="screen-title">{{$t('gallery.elderScreen')}}</p>
          <p class="screen-description">{{$t('gallery.elderScreenContent')}}
          </p>
        </a-col>
      </a-col>
    </a-row>
    <a-row class="role-wrap">
      <a-col :span="24" class="role-item">
        <a-col :span="7" class="img">
          <img src="../assets/nft-items/r/r1.png">
        </a-col>
        <a-col :span="16" :offset="1">
          <p class="role-title">{{$t('gallery.sageRole')}}</p>
          <p class="role-description">{{$t('gallery.sageRoleContent')}}</p>
        </a-col>
      </a-col>
      <a-col :span="24" class="role-item">
        <a-col :span="7" class="img">
          <img src="../assets/nft-items/r/r2.png">
        </a-col>
        <a-col :span="16" :offset="1">
          <p class="role-title">{{$t('gallery.lotusRole')}}</p>
          <p class="role-description">{{$t('gallery.lotusRoleContent')}}</p>
        </a-col>
      </a-col>
      <a-col :span="24" class="artist">
        <img src="../assets/member/artist.png">
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Gallery",
  mounted() {
    this.$store.commit('switchNavi', 1)
  },
  computed: {
    ...mapState(['account', 'web3', 'networkid', 'nav', 'itemContract']),
  },
  data() {
    return {
      mintNumber: 10,
      mintPopup: false,
    }
  },
  methods: {
    subMint() {
      if (this.mintNumber <= 0) {
        return;
      }
      this.mintNumber--;
    },
    addMint() {
      this.mintNumber++;
    },
    hideMint() {
      this.mintPopup = false
    },
    mint() {
      this.mintPopup = true
    },
    async _mint() {
      if (this.itemContract) {
        const gasPrice = await this.web3.eth.getGasPrice()
        const price = 0.025 * 10**18
        const gasLimit = await this.itemContract.methods.mint(this.mintNumber).estimateGas({
          from: this.account,
          value: price * this.mintNumber,
        })

        this.itemContract.methods.mint(this.mintNumber).send({
          from: this.account,
          value: price * this.mintNumber,
          gas: gasLimit,
          gasPrice,
        }, function(err, hash) {
          // console.log(err, hash)
        }).on('transactionHash', function(hash) {
          // console.log(hash)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .gallery {
    .gallery-logo {
      text-align: center;
      img {
        margin: 1rem 0;
        width: 300px;
      }
    }

    .art-preview {
      text-align: center;
      img {
        width: 360px;
        margin: 0 10px 50px;
      }
    }

    .split {
      border-top: 1px solid #979797;
      height: 1px
    }

    .sub-title, .description {
      text-align: center;
    }

    .sub-title {
      font-size: 30px;
      margin: 40px 0 20px;
    }

    .description {
      font-size: 18px;
    }

    .nft-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #979797;
      padding: 1rem 0;

      .img {
        text-align: center;
      }

      img {
        width: 140px;
      }

      .btn-wrap {
        text-align: center;
      }

      .mint-number {
        font-size: 32px;
      }

      .nft-description {
        font-size: 18px;
      }

      .mint-button {
        width: 140px;
        height: 40px;
        line-height: 40px;
        background: #CFCFCF;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        color: #131415;
        font-weight: bold;
        cursor: not-allowed;

        &.mint {
          background: #FF750D;
          cursor: pointer;
        }
      }

      &.border-none {
        border: none;
      }
    }

    .screen-item {
      display: flex;
      align-items: center;

      .img {
        text-align: center;
      }

      img {
        width: 100%;
      }

      .screen-title {
        font-size: 24px;
        color: black;
        font-weight: 500;
      }

      .screen-description {
        font-size: 18px;
        padding-right: 8rem;
      }
    }

    .role-item {
      display: flex;
      margin-bottom: 2rem;
      align-items: center;

      .img {
        text-align: left;
      }

      img {
        max-width: 300px;
      }

      .role-title {
        font-size: 24px;
        color: black;
        font-weight: 500;
      }

      .role-description {
        font-size: 18px;
      }
    }

    .screen-wrap {
      background: #D8D8D8;
    }

    .artist {
      background: #D8D8D8;
      padding: 20px 5%;
      img {
        width: 100%;
      }
    }
  }
</style>
