<template>
  <div class="vipBox">
    <div class="title">VIP Claim</div>
    <div class="btnBox">
      <button type="button" class="claim" v-if="isClaim" @click="claim">Claim</button>
      <button type="button" disabled v-else>Sorry, you are not in the grant list.</button>
    </div>
    <div class="infoBox">
      <p>Each of our investors and major partners can claim a unique, special piece for free. They are hidden symbols that signal your distinctive status and/or important contribution to the project, and cannot be obtained from regular channels. The right to claim one is reserved for VIPs which are to be differentiated from the collectors on the whitelist.</p>
    </div>
    <div class="imgBox">
      <img src="../assets/grant/frog.png" alt="">
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "vip",
  data() {
    return {
      isClaim: true,
      timer: null
    }
  },
  computed: {
    ...mapState(['account', 'web3', 'networkid', 'nav', 'nftContract', 'itemContract']),
  },
  async mounted() {
    this.$store.commit('switchNavi', -1)
    await this.checkWhiteList()

    const _this = this
    const timer = setInterval(async () => {
      await _this.checkWhiteList()
    }, 2000)
    this.timer = timer
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
     async checkWhiteList() {
      if (this.nftContract && this.account) {
        const ifWhiteList = await this.nftContract.methods.IfWhiteList(this.account).call()
        this.isClaim = ifWhiteList
        clearInterval(this.timer)
        this.timer = null
      }
    },

    async claim() {
      if (this.isClaim) {
        const gasPrice = await this.web3.eth.getGasPrice()
        try {
          const gasLimit = await this.nftContract.methods.airdrop().estimateGas({
            from: this.account
          })
          this.nftContract.methods.airdrop().send({
            from: this.account,
            gasPrice,
            gas: gasLimit,
          }, function(err, hash) {

          }).on('transactionHash', function(hash) {
            location.href = `https://etherscan.io/tx/${hash}`
          })
        } catch(err) {
          if (err.message.indexOf('Unqualified')) {
            alert('Unqualified')
          } else {
            alert(err.msg)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .vipBox {
    width: 750px;
    margin: 0 auto;
    padding: 50px 0;
    .title {
      width: 750px;
      text-align: center;
      font-size: 44px;
      color: #000;
      margin-bottom: 20px;
    }
    .btnBox {
      width: 750px;
      text-align: center;
      button {
        cursor: pointer;
        width: 500px;
        height: 60px;
        font-size: 24px;
        color: #111;
        border: 0;
        background: #E0E0E0;
        border-radius: 16px;
      }
      button.claim {
        background-color: #FFC938;
      }
    }
    .infoBox {
      width: 700px;
      margin: 50px auto 0;
      p {
        font-size: 20px;
        color: #131415;
        text-align: justify;
      }
    }
    .imgBox {
      width: 750px;
      text-align: center;
      img {
        width: 750px;
      }
    }
  }
</style>
